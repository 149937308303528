<template>
  <div>

    <base-header class="pt-5 pt-md-8 bg-gradient-orange">
      
    </base-header>

      <b-container fluid class="mt--7">
        <!--Tables-->
        <b-row class="mt-5">
          <b-col xl="12" class="mb-5 mb-xl-0">
            <users-table></users-table>
          </b-col>
        </b-row>
        <!--End tables-->
      </b-container>

</div>
</template>
<script>
  // Tables
  import usersTable from './Dashboard/UsersTable';

  import MatchHeight from 'matchheight';

  export default {
    components: {
      usersTable
    },
    computed: {
    },
    data() {
      return {
      };
    },
    methods: {
    },
    mounted() {
    }
  };
</script>
<style>
.el-table .cell{
  padding-left: 0px;
  padding-right: 0px;
}
</style>
